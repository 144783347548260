import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Col, Row, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDesktop,
  faMobileAlt,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

const ExpandableServiceCard = ({
  id,
  expandedCard,
  setExpandedCard,
  icon,
  title,
  summary,
  details,
  price,
  delay,
}) => {
  const contentRef = useRef(null);

  const toggleExpansion = () => {
    if (expandedCard === id) {
      setExpandedCard(null); // Close this card if it's already open
    } else {
      setExpandedCard(id); // Open this card
    }
  };

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, []);

  useEffect(() => {
    if (expandedCard === id) {
      contentRef.current.style.maxHeight =
        contentRef.current.scrollHeight + "px";
    } else {
      contentRef.current.style.maxHeight = null;
    }
  }, [expandedCard, id]);

  return (
    <Col sm={12} md={12} lg={4} className="mb-4">
      <Card
        onClick={toggleExpansion}
        className={`text-center service-card clickable ${
          animate ? `card-animate-pop delay-${delay}` : "hidden-initially"
        }`}
      >
        <Card.Body>
          <div className="service-card-icon">
            <FontAwesomeIcon icon={icon} size="3x" />
          </div>
          <Card.Title>{title}</Card.Title>
          <Card.Text>{summary}</Card.Text>
          <div ref={contentRef} className="additional-info">
            <p>{details}</p>
          </div>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-between align-items-center">
          <span className="price-tag">{price}</span>
          <span className="toggle-info">
            {expandedCard === id ? "Show Less" : "Learn More"}
          </span>
        </Card.Footer>
      </Card>
    </Col>
  );
};

const ServicesScreen = () => {
  const navigate = useNavigate();
  const [expandedCard, setExpandedCard] = useState(null);

  const handleContactNavigate = () => {
    navigate("/contact");
  };

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, []);

  return (
    <div>
      <Container>
        <h1
          className={`text-center  page-title ${
            animate ? "heading-text-animation delay-1" : "hidden-initially"
          }`}
        >
          Our Services
        </h1>
        <div
          id="outter-divider"
          className={` ${
            animate ? "heading-line-animation delay-2" : "hidden-initially"
          }`}
        ></div>

        <Row>
          <ExpandableServiceCard
            id={1}
            expandedCard={expandedCard}
            setExpandedCard={setExpandedCard}
            icon={faDesktop}
            title="Software Development"
            summary="We will design, build, and host high-quality applications built to your specifications."
            details="There is an application for everything, but rarely one that does everything. We can build an application that fulfills all your business needs, allowing you to spend less time learning new systems and more time focusing on what matters."
            price="'I have an Idea!'"
            delay={3}
          />
          <ExpandableServiceCard
            id={2}
            expandedCard={expandedCard}
            setExpandedCard={setExpandedCard}
            icon={faMobileAlt}
            title="Technology Consulting"
            summary="We stay up-to-date on emerging technologies so you don't have to."
            details="AI, Large Language Models, LiDAR Drone Surveys, Robotic Logistics, Self-Driving Cars! If you could know about every tool that could improve your business, would you want to? We provide you with recommendations on all emerging and current technologies and help you figure out when and how to implement them, as well as a whole host of other services to help you stay ahead of the curve."
            price="'We need to Advance!'"
            delay={4}
          />
          <ExpandableServiceCard
            id={3}
            expandedCard={expandedCard}
            setExpandedCard={setExpandedCard}
            icon={faSearch}
            title="Search Engine Optimization"
            summary="We know how to attract more attention to your website and rank you higher on search engines."
            details="You have better things to do with your time than figuring out how to get your website to the top of Google search results. We can help you get there and stay there, so you can focus on what you do best while we focus on what we do best—making sure people can find you when they need you."
            price="'We need more customers!'"
            delay={5}
          />
        </Row>
      </Container>
      <br></br>
      <br></br>
      <Container style={{ textAlign: "center" }}>
        <h1
          className={` ${
            animate ? "flip-animation delay-6" : "hidden-initially"
          }`}
        >
          Need something custom?
        </h1>
        <h2
          className={` ${
            animate ? "flip-animation delay-7" : "hidden-initially"
          }`}
        >
          Set up a free consultation
        </h2>
        <br></br>

        <div
          style={{ textAlign: "center" }}
          className={` ${
            animate ? "card-animate-roll delay-8" : "hidden-initially"
          }`}
        >
          <button
            className="btn btn-rect-to-round btn-rect-to-round--blue"
            onClick={handleContactNavigate}
          >
            Contact Us
          </button>
        </div>
      </Container>

      <br></br>
      <br></br>
    </div>
  );
};

export default ServicesScreen;
