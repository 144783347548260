import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/logo.png";

const Footer = () => {
  return (
    <footer className="custom-footer mt-5">
      <Container fluid className="py-3">
        <Row>
          <Col md={4} className="text-center">
            <h5>Contact Us</h5>
            <p>
              Email:{" "}
              <a href="mailto:info@digitalliftconsulting.ca">
                info@digitalliftconsulting.ca
              </a>
            </p>
            <p>
              Phone:{" "}
              <a href="tel:2894569065">(289) 456-9065</a>
            </p>
          </Col>
          <Col md={4} className="text-center text-md-left inline">
            <img
              src={logo}
              alt="Digital Lift Consulting Logo"
              style={{ height: "80px" }}
            />
            <div className="DLC">
              <p className="letters">D</p>
              <p className="lettersMid">L</p>
              <p className="letters">C</p>
            </div>
          </Col>
          <Col md={4} className="text-center text-md-right">
            <h5>Available Between</h5>
            <p>
              9am - 5pm EST
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
